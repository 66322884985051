<template>
  <st-form-title v-if="label" :formTitle="label" :required="required" class="mt-32" />
  <div :class="classFile">
    <div
      class="px-12 relative rounded-xl h-72 border-1 border-solid border-border bg-[var(--stds-glob-color-gray20)] flex items-center gap-12"
    >
      <div
        class="w-48 h-48 rounded-lg bg-[var(--stds-glob-color-gray60)] flex justify-center items-center shrink-0 overflow-hidden"
      >
        <s-icon
          v-if="!fileName"
          size="xl"
          icon="ic-v2-editor-attach-file-line"
          class="text-on-surface-elevation-1 flex"
        />
        <div v-else class="w-full h-full relative border-1 border-solid border-border rounded-lg">
          <img
            v-if="isImgIcon"
            src="@/assets/images/common/icon-Image.svg"
            alt=""
            class="w-full h-full object-cover"
            :class="{ 'opacity-50': isDisabled }"
          />
          <img
            v-else
            src="@/assets/images/common/icon-pdf.svg"
            alt=""
            class="w-full h-full object-cover"
            :class="{ 'opacity-50': isDisabled }"
          />
          <button
            type="button"
            class="absolute inset-0 p-4 flex items-end justify-end"
            @click="downloadFile"
          >
            <span class="w-20 h-20 rounded-full flex items-center justify-center bg-black/50">
              <s-icon size="xs" icon="ic-v2-object-download-line" class="text-white flex" />
            </span>
          </button>
        </div>
      </div>
      <div class="flex-1 flex flex-col gap-[.2rem]">
        <div v-if="fileName && fileName.length > 0" class="flex gap-8">
          <p
            class="break-all text-xs leading-xs font-medium line-clamp-1"
            :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
          >
            {{ fileName }}
          </p>
          <button
            type="button"
            class="h-16 w-16 text-on-surface-elevation-4"
            :class="{ hidden: isDisabled }"
            @click="removeFile"
          >
            <s-icon size="xl" icon="ic-v2-control-close-circle-fill" class="flex" />
          </button>
        </div>
        <template v-else>
          <p
            v-if="fileDesc1 && fileDesc1.length > 0"
            class="text-xs leading-xs font-medium text-on-surface-elevation-2"
            :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
          >
            {{ fileDesc1 }}
          </p>
          <p
            v-if="fileDesc2 && fileDesc2.length > 0"
            class="text-xs leading-xs font-regular"
            :class="isDisabled ? 'text-disabled-variant-1' : 'text-placeholder'"
          >
            {{ fileDesc2 }}
          </p>
        </template>
      </div>
      <input-file :name="name" multiple :base64="false" :rules="rules" @fileSelect="getNameFile">
        <template #default="{ triggerUpload }">
          <s-button
            variant="outline"
            size="sm"
            class="shrink-0"
            :isDisabled="isDisabled"
            @click="triggerUpload"
          >
            {{ buttonText ?? $t('studio.business_bank_setting.individual.btn_attach') }}
          </s-button>
        </template>
      </input-file>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { ref, watch } from 'vue';

import { downloadContractApi, uploadContractApi } from '@/apis/business-bank-info.api';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputFile from '@/components/validation/input-file.vue';
import { IMAGE_EXTENSION, IMAGE_FILE_EXTENSION } from '@/constants/file.const';
import type { BusinessBankContract } from '@/types/common/file.type';

const fileUrl = ref<string>();

const props = withDefaults(
  defineProps<{
    label?: string;
    name: string;
    rules?: any;
    buttonText?: string;
    fileDesc1?: string;
    groupId: string;
    fileDesc2?: string;
    isDisabled?: boolean;
    classFile?: string;
    contractNo?: number[];
    required?: boolean;
  }>(),
  {
    label: '',
    rules: {},
    buttonText: '',
    fileDesc1: '',
    fileDesc2: '',
    isDisabled: false,
    classFile: '',
    contractNo: () => [],
    required: true
  }
);

const emits = defineEmits<{
  onUploadFile: [v: BusinessBankContract];
  removeFile: [];
}>();

const file = useFieldValue<string>(props.name);
const fileName = ref<string>('');
const isImgIcon = ref<boolean>(false);

const removeFile = () => {
  fileName.value = '';
  emits('removeFile');
};

const downloadFile = async () => {
  if (props.contractNo && props.contractNo[0]) {
    const res = await downloadContractApi(props.groupId || '', props.contractNo[0] || 0);
    if (res) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = fileName.value;
      link.click();
      link.remove();
    }
  }
};

const getNameFile = async (e: File[]) => {
  isImgIcon.value = IMAGE_EXTENSION.includes(e[0].type);

  const res = await uploadContractApi(props.groupId || '', e);
  if (res) {
    if (fileUrl.value) {
      URL.revokeObjectURL(fileUrl.value);
    }

    fileUrl.value = URL.createObjectURL(e[0]);

    emits('onUploadFile', res);
    fileName.value = res.fileName;
  }
};

watch(
  () => file.value,
  (newValue: string) => {
    if (typeof newValue !== 'string') {
      return;
    }
    fileName.value = newValue;
    isImgIcon.value = Object.values(IMAGE_FILE_EXTENSION)
      .map((e: string) => e.replace('.', ''))
      .includes(newValue.split('.').pop()?.toLowerCase() || '');
  },
  {
    immediate: true
  }
);
</script>
